/* hero hover cards layout true management -css ---------------------------------p */
.true_card_bg {
  background-image: url("../src/assets/images/webp/true_management.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  background-position: center;
}

.vulnerabilty_card_bg {
  background-image: url("../src/assets/images/webp/vulnerabilty_management.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  background-position: center;
}

.resilent_card_bg {
  background-image: url("../src/assets/images/webp/resilent_management.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

.based_card_bg {
  background-image: url("../src/assets/images/webp/based_management.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
  position: relative;
  background-position: center;
}
.mw_solution_para {
  max-width: 250px;
}

.true_card_bg::before,
.vulnerabilty_card_bg::before,
.resilent_card_bg::before,
.based_card_bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#101011),
    to(#101011)
  );
  background: -o-linear-gradient(top, #101011 0%, #101011 100%);
  background: linear-gradient(180deg, #101011 0%, #101011 100%);
  -webkit-transition-property: width !important;
  -o-transition-property: width !important;
  transition-property: width !important;
  opacity: 0.8;
}

.based_card_bg:hover::before,
.resilent_card_bg:hover::before,
.vulnerabilty_card_bg:hover::before,
.true_card_bg:hover::before {
  height: 0;
  opacity: 0;
}
.based_card_bg:hover h2,
.resilent_card_bg:hover h2,
.vulnerabilty_card_bg:hover h2,
.true_card_bg:hover h2 {
  font-weight: 700 !important;
}

.custom_width_solution .card-hover-para {
  display: none;
}

.custom_width_solution:hover .card-hover-para {
  display: inline;
}

.custom_width_solution {
  width: 25%;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.custom_width_solution:hover {
  width: 40%;
}
.heading_line::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: #002baa;
  left: -20px;
  top: 0;
}
.solution_card:hover .center_text {
  display: none;
}
.shadow_one::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 40px;
  background-color: rgb(0, 0, 0);
  filter: blur(35px);
  left: 0;
  top: -15px;
}
.shadow_one::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 40px;
  background-color: rgb(0, 0, 0);
  filter: blur(35px);
  left: 0;
  bottom: -15px;
}
.center_text {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  z-index: 11;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}
/*OUR GOALS CSS START*/
#react-player-goals-video {
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
}
.goals_heading_text {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.text_border {
  color: transparent;
  -webkit-text-stroke: 1px white;
}

.numbers_min_height {
  min-height: 90px;
}

.goals_para_hover {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.goals_blur_hover {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.goals_blur_hover:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}

.goals_blur_hover:hover .goals_text_parent {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.board-of-ourgoals-accordion .accordion-button span {
  min-height: 0 !important;
  line-height: 0 !important;
  margin-bottom: 6px !important;
}

.goals_blur_hover .goals_text_parent {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.goals_text_parent {
  min-height: 410px !important;
}
.goals_para_hover {
  max-width: 250px;
}

.goals_blur_hover:hover .goals_para_hover {
  opacity: 1;
}

.bg-gradient-white_goals {
  border-right: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(248, 250, 254, 0) 0%,
    rgba(248, 250, 254, 0) 23.61%,
    #f8fafe 48.61%,
    rgba(255, 255, 255, 0) 74.13%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}

.learn_more_text_none {
  display: none;
}

.learn_more_text {
  display: block;
}
.footer_link:hover {
  opacity: 0.7;
}
.mw_industry {
  max-width: 700px;
}
.z_negative_1 {
  z-index: -1;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
}
.bg_position {
  background-position: bottom;
}
@media (max-width: 1499.98px) {
  .circle_img {
    max-width: 750px;
  }
}

@media (max-width: 1199.98px) {
  .gold_section {
    min-height: 500px !important;
  }
  .Benefits_cards {
    height: 220px;
  }
}
@media (max-width: 991.98px) {
  .circle_img {
    max-width: 500px;
  }
  .navbar-overlay li a {
    font-size: 20px !important;
    margin: 10px 0;
    display: inline-block;
  }
  .true_card_bg,
  .vulnerabilty_card_bg,
  .based_card_bg,
  .resilent_card_bg {
    height: auto;
  }
  .true_card_bg .accordion-button,
  .vulnerabilty_card_bg .accordion-button,
  .based_card_bg .accordion-button,
  .resilent_card_bg .accordion-button {
    /* height: 300px !important; */
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .true_card_bg .accordion-button.collapsed,
  .vulnerabilty_card_bg .accordion-button.collapsed,
  .based_card_bg .accordion-button.collapsed,
  .resilent_card_bg .accordion-button.collapsed {
    height: auto !important;
  }
  .true_card_bg::before,
  .vulnerabilty_card_bg::before,
  .resilent_card_bg::before,
  .based_card_bg::before {
    opacity: 0.5;
  }
  .based_card_bg:hover::before,
  .resilent_card_bg:hover::before,
  .vulnerabilty_card_bg:hover::before,
  .true_card_bg:hover::before {
    opacity: 0.5;
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .numbers_min_height {
    min-height: unset;
  }

  .bg-gradient-white_goals {
    border-right: unset;
  }

  .goals_para_hover {
    max-width: 500px;
  }

  .solution_card h2 {
    font-size: 17px;
  }
  .solution_card p {
    font-size: 15px;
  }
}
@media (max-width: 575.98px) {
  .solution_card h2 {
    font-size: 10px;
  }
  .solution_card p {
    font-size: 10px;
  }
  .heading_line::after {
    width: 2px;
    left: -5px;
  }
}
@media (min-width: 1600px) {
  .gold_section {
    min-height: 780px !important;
  }
  .h-100vh {
    height: 800px;
  }
  .default-bg-img-industrial {
    background-size: 100% 100%;
  }
}
@media (min-width: 1700px) {
  .gold_section {
    min-height: 900px !important;
  }
}

/* card-css */
#react-player-card-video {
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
}
.z_index_0 {
  z-index: 0;
}
.z_index_99 {
  z-index: 99 !important;
}
/* .positioning_content_card {
  top: 62px;
  left: 62px;
} */
.bg_cards {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#002baa),
    to(rgba(0, 43, 170, 0))
  ) !important;
  background: -o-linear-gradient(
    right,
    #002baa 0%,
    rgba(0, 43, 170, 0) 100%
  ) !important;
  background: linear-gradient(
    270deg,
    #002baa 0%,
    rgba(0, 43, 170, 0) 100%
  ) !important;
}
.board-of-director-accordion .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.board-of-director-accordion .accordion-button {
  background-color: transparent;
  padding-top: 25px;
  padding-bottom: 25px;
}

.board-of-director-accordion .accordion-button span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 90px;
  color: white;
  border-left: 2px solid white;
  padding-left: 10px;
}

.board-of-director-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/images/svg/arrowdown.svg");
}

.board-of-director-accordion .accordion-button::after {
  background-image: url("./assets/images/svg/arrowdown.svg");
}
