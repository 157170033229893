.opactiy_8 {
  opacity: 0.8;
}

.hero_text {
  height: 500px;
  -webkit-transition: all 300ms ease-in-out !important;
  -o-transition: all 300ms ease-in-out !important;
  transition: all 300ms ease-in-out !important;
  position: relative;
}

.hero_section {
  z-index: 5;
}

.hero_section::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.329);
  z-index: 1;
}

.homepage_hero_text {
  z-index: 2;
}

/* .hero_section #react-player-value {
  z-index: 3;
} */

.react-player video {
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 100% !important;
}

.hero_text::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #101011;
  opacity: 0.5;
  height: 100%;
  z-index: 9;
  -webkit-transition: all 300ms linear !important;
  -o-transition: all 300ms linear !important;
  transition: all 300ms linear !important;
}

.index_1 {
  z-index: 1;
}

.postioning_text {
  position: absolute;
  bottom: 50% !important;
  width: 100%;
  z-index: 99;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  -webkit-transition: all 0.5s ease-in-out !important;
  -o-transition: all 0.5s ease-in-out !important;
  transition: all 0.5s ease-in-out !important;
}

.hero_text:hover::after {
  height: 0;
  background: none !important;
  -webkit-transition: all 0.4s linear !important;
  -o-transition: all 0.4s linear !important;
  transition: all 0.4s linear !important;
}

.hero_text:hover .postioning_text {
  bottom: 5% !important;
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  -webkit-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
  text-align: start !important;
}

.hero_text p {
  -webkit-transition: height 1s ease-in-out !important;
  -o-transition: height 1s ease-in-out !important;
  transition: height 1s ease-in-out !important;
}

.hero_text:hover p {
  opacity: 0;
  height: 0;
  display: block !important;
}
.text_justify {
  text-align: justify;
}
/* CONTACT-CSS START ------------------------------------------------------------ */
.form_bg_clases {
  background: rgba(248, 250, 254, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  margin-top: -80px;
}

.input-common-clases {
  padding: 13px 0px 13px 22px;
  border-radius: 35px;
  border: 1px solid rgba(174, 175, 178);
  background: #f8fafe;
  color: black;
  font-size: 16px;
  /* opacity: 0.3; */
}

.form_bg_clases select::-webkit-input-placeholder {
  color: red !important;
}

.form_bg_clases select::-moz-placeholder {
  color: red !important;
}

.form_bg_clases select:-ms-input-placeholder {
  color: red !important;
}

.form_bg_clases select::-ms-input-placeholder {
  color: red !important;
}

.form_bg_clases select::placeholder {
  color: red !important;
}

/* .border_left {
  position: relative;
} */

.border_left {
  border-left: 2px solid black;
  width: 2px;
  height: 150px;
  margin-left: 100px;
}

.form_bg_clases select {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url("./assets/images/svg/arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95%;
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.568);
}

.submit-btn {
  background: rgba(24, 70, 157, 1);
  padding: 14px 28px;
  border-radius: 49px;
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(24, 70, 157, 1);
}

.submit-btn:hover {
  color: rgba(24, 70, 157, 1);
  background-color: #fff;
}

.input-common-clases:focus-visible {
  outline: none !important;
}

/* footer-input css -------------------------------------------------------------------- */
.sign-up-btn {
  background: rgba(3, 8, 18, 1);
  padding: 14px 28px;
  border-radius: 49px;
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgb(0, 0, 0);
}

.sign-up-btn:hover {
  color: rgb(0, 0, 0);
  background-color: #fff;
}

input:focus-visible {
  outline: none !important;
}

.footer_icon_hover:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

a {
  color: #fff !important;
}

@media (max-width: 576px) {
  .sign-up-btn {
    padding: 10px 20px;
  }

  .input-common-clases {
    padding: 10px 0px 10px 22px;
  }
}

/* 3rd page cards css ------------------------------------------ */

video {
  min-width: 100%;
  min-height: 100vh;
  z-index: 1;
}

.overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: -o-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.7) 50%
  );
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.7) 50%
  );
  background-size: 3px 3px;
  z-index: 2;
}

.rendom {
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  max-width: 400px;
  width: 100%;
  height: 50px;
}

.devepmemt_card_hov {
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: relative;
}

.devepmemt_card_hov::after {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-right: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(248, 250, 254, 0) 0%,
    rgba(248, 250, 254, 0) 23.61%,
    #f8fafe 48.61%,
    rgba(255, 255, 255, 0) 74.13%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}

.devepmemt_card_hov img,
.devepmemt_card_hov h5 {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.devepmemt_card_hov:hover img,
.devepmemt_card_hov:hover h5 {
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}

.devepmemt_card_hov:hover {
  background: rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  transform: translateY(-7px);
}

.card_blur_hover {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.card_blur_hover:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}

.overflow_hidden {
  overflow: hidden;
}

.transition-all-300ms {
  -webkit-transition: all 2s ease;
  -o-transition: all 2s ease;
  transition: all 2s ease;
}

/* INDUSTRY SECTION */
.sector_card {
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.sector_card:hover .svg_image svg rect {
  fill: #002baa;
}
.sector_card:hover .svg_image svg circle {
  fill: #002baa;
}
.sector_card:hover .svg_image svg .city_icon {
  stroke: white;
  fill: #002baa;
}

.sector_card:hover .svg_image svg path {
  fill: #f8fafe;
}

.sector_card:hover {
  background: #0076c8;
}

.sector_card:hover .sector_text {
  color: #f8fafe;
}

/* SECTORS BACKGROUNDS */
.transport {
  background-image: url("./assets/images/webp/transport.webp");
  background-size: cover;
  background-position: center;
}

.electricity {
  background-image: url("./assets/images/webp/electricity.webp");
  background-size: cover;
  background-position: center;
}

.defence {
  background-image: url("./assets/images/webp/defence.webp");
  background-size: cover;
  background-position: center;
}

.health {
  background-image: url("./assets/images/webp/health.webp");
  background-size: cover;
  background-position: center;
}

.industrial_images {
  opacity: 0;
}

.oil {
  background-image: url("./assets/images/webp/oil.webp");
  background-size: cover;
  background-position: center;
}

.interrior {
  background-image: url("./assets/images/webp/Smart-City.webp");
  background-size: cover;
  background-position: center;
}

.default-bg-img-industrial {
  background-image: url("./assets/images/webp/industrial-sector-img.webp");
  background-size: cover;
  background-position: center;
}

.cards_section_solution {
  min-height: 100vh;
}

.min_h_100vh {
  min-height: 100vh;
}

@media (max-width: 991.980.98px) {
  .m_top_5 {
    margin-top: -6px;
  }
}

.m_top_5 {
  margin-top: -7px;
}

@media (max-width: 991.98px) {
  .devepmemt_card_hov::after {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .rose-video-height {
    height: 524px;
  }
}

@media (max-width: 575.98px) {
  #react-player-value {
    height: 75vh !important;
  }

  .rose-video-height {
    height: 627px;
  }

  .box-width {
    width: 100% !important;
  }
}

.opacity_0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.sector_card:hover .imges {
  opacity: 1;
}

.z_negative_2 {
  z-index: -2;
}

.transition_all_200ms {
  -webkit-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}

.transition_normal {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
