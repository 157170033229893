.bg_dark_blue {
  background: #18469d;
}
.opacity_hover_1:hover {
  opacity: 1;
}

.object_fit_cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}
.bg_light_blue {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#002baa),
    to(rgba(0, 43, 170, 0))
  );
  background: -o-linear-gradient(right, #002baa 0%, rgba(0, 43, 170, 0) 100%);
  background: linear-gradient(270deg, #002baa 0%, rgba(0, 43, 170, 0) 100%);
  position: relative;
}

/* ==========LEADING-VIDEO-SECTION============ */
.leading_position {
  right: 10px;
  bottom: 20px;
}
.leading_box_img video {
  -o-object-fit: cover !important;
  object-fit: cover !important;
  -o-object-position: right !important;
  object-position: right !important;
}

/* ==========DIRECTOR============ */
.directory_box {
  min-height: 475px !important;
}
.directory_para_width {
  max-width: 690px !important;
}
/* ============GOLD============= */

.gold_section {
  min-height: 630px !important;
}
.gold_section_video video {
  min-height: 700px !important;
}

/* ===========OURGOALS-PAGE========== */

.board-of-ourgoals-accordion .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.board-of-ourgoals-accordion .accordion-button {
  background-color: transparent;
  padding-top: 25px;
  padding-bottom: 25px;
}

.board-of-ourgoals-accordion .accordion-button span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 60px;
  color: white;
  padding-left: 10px;
}

.board-of-ourgoals-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/images/svg/arrowdown.svg");
}

.board-of-ourgoals-accordion .accordion-button::after {
  background-image: url("./assets/images/svg/arrowdown.svg");
}
.board-of-ourgoals-accordion .accordion-item {
  -webkit-transform: translateY(150px);
  -ms-transform: translateY(150px);
  transform: translateY(150px);
  border: none !important;
}
.board-of-ourgoals-accordion .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
/* .learn_more_btn {
  bottom: 20px !important;
} */
.ourgoal_vedio {
  height: 100vh !important;
}

/* ===========CONTACT-PAGE========== */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input_focus input:focus,
textarea:focus,
select:focus,
.sign_up_active:hover {
  border: 1px solid var(--color-light-black) !important;
  -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.623);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.623);
}
.gold_video_static {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* ===================MEDIA-QUERY============== */

@media (max-width: 991.98px) {
  .leading_box_img video,
  .leading_text_box,
  .leading_hourse_height {
    min-height: 468px !important;
  }
  .gold_section {
    min-height: 500px !important;
  }
  .gold_section_video video {
    min-height: 300px !important;
    max-height: 300px !important;
  }
  .gold_video_static {
    position: static !important;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@media (max-width: 767.98px) {
  .ourgoal_vedio {
    height: 800px !important;
  }
}
@media (max-width: 576.98px) {
  .transform_devlepment {
    transform: translateY(35px);
  }
  .width_adjust {
    width: 80px;
    height: 80px;
  }
}
@media (min-width: 992px) {
  .h_lg_100vh {
    height: 100vh !important;
  }
  .position_lg_absolute {
    position: absolute !important;
  }
}

@media (min-width: 1400px) {
  .leading_para_width {
    max-width: 66%;
  }
}
