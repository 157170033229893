@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500&family=Lato&display=swap");
@import url("../src/style.css");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700&display=swap");

body {
  font-family: "Lato", sans-serif !important;
  background-color: black !important;
}

.ff_josfin {
  font-family: "Josefin Sans", sans-serif !important;
}

:root {
  --font-xxs: 12px;
  --font-xs: 14px;
  --font-sm: 16px;
  --font-md: 20px;
  --font-lg: 24px;
  --font-xl: 32px;
  --font-2xl: 40px;
  --font-3xl: 48px;
  --font-4xl: 64px;
  --color-dark-blue: rgba(0, 43, 170, 1);
  --color-white: #ffffff;
  --color-blue: #002baa;
  --color-light-white: #f8fafe;
  --color-black: #000000 !important;
  --color-light-black: #101011;
  --bg-dark-black: #030812;
  --bg-light-white: #f6f6f6;
  --bg-light-gray: #f0f0f0;
}

/* ----------------------------------- ONLY-MEDIA-QUERIES ----------------------------------- */
@media (max-width: 1399.98px) {
  :root {
    --font-xxs: 12px;
    --font-xs: 14px;
    --font-sm: 16px;
    --font-sm2: 18px;
    --font-md: 20px;
    --font-lg: 22px;
    --font-xl: 28px;
    --font-2xl: 35px;
    --font-3xl: 45px;
    --font-4xl: 60px;
  }
}

@media (max-width: 1199.98px) {
  :root {
    --font-xxs: 12px;
    --font-xs: 12px;
    --font-sm: 14px;
    --font-sm2: 16px;
    --font-md: 18px;
    --font-lg: 20px;
    --font-xl: 25px;
    --font-2xl: 32px;
    --font-3xl: 40px;
    --font-4xl: 55px;
  }
}

@media (max-width: 991.98px) {
  :root {
    --font-xxs: 12px;
    --font-xs: 13px;
    --font-sm: 14px;
    --font-sm2: 16px;
    --font-md: 18px;
    --font-lg: 20px;
    --font-xl: 25px;
    --font-2xl: 32px;
    --font-3xl: 35px;
    --font-4xl: 50px;
  }
}

@media (max-width: 767.98px) {
  :root {
    --font-xxs: 12px;
    --font-xs: 13px;
    --font-sm: 14px;
    --font-sm2: 14px;
    --font-md: 18px;
    --font-lg: 20px;
    --font-xl: 21px;
    --font-2xl: 25px;
    --font-3xl: 30px;
    --font-4xl: 40px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --font-xxs: 12px;
    --font-xs: 13px;
    --font-sm: 14px;
    --font-sm2: 14px;
    --font-md: 18px;
    --font-lg: 20px;
    --font-xl: 20px;
    --font-2xl: 23px;
    --font-3xl: 25px;
    --font-4xl: 35px;
  }
}
@media (max-width: 375.98px) {
  :root {
    --font-md: 15px;
    --font-lg: 15px;
    --font-xl: 16px;
  }
}

.font-xxs {
  font-size: var(--font-xxs) !important;
}

.font-xs {
  font-size: var(--font-xs) !important;
}

.font-sm {
  font-size: var(--font-sm) !important;
}

.font-sm2 {
  font-size: var(--font-sm2) !important;
}

.font-md {
  font-size: var(--font-md) !important;
}

.font-lg {
  font-size: var(--font-lg) !important;
}

.font-xl {
  font-size: var(--font-xl) !important;
}

.font-2xl {
  font-size: var(--font-2xl) !important;
}

.font-3xl {
  font-size: var(--font-3xl) !important;
}

.font-4xl {
  font-size: var(--font-4xl) !important;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500 !important;
}

.font-light {
  font-weight: 300 !important;
}

.transition_03s {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.color-dark-blue {
  color: var(--color-dark-blue);
}

.color-white {
  color: var(--color-white);
}

.color-blue {
  color: var(--color-blue);
}

.color-light-white {
  color: var(--color-light-white) !important;
}

.color-black {
  color: var(--color-black);
}

.color-light-black {
  color: var(--color-light-black) !important;
}

.bg-dark-black {
  background-color: var(--color-dark-black);
}

.bg-dark-blue {
  background-color: var(--color-dark-blue);
}

.bg-light-white {
  background-color: var(--bg-light-white);
}

.bg-light-gray {
  background-color: var(--bg-light-gray);
}

.opacity-07 {
  opacity: 0.7;
}

.coursor-pointer {
  cursor: pointer;
}

.parallax_text {
  font-family: "Cairo", sans-serif;
  font-size: var(--font-4xl);
  font-weight: 200;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 5;
  color: white;
  width: 100%;
  text-align: center;
  padding: 20px;
}

/* HERO SECTION HERE */
.z_index_9999 {
  z-index: 9999;
}

#react-player-value {
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
}

.react-player video {
  -o-object-fit: cover;
  object-fit: cover;
}

/* FOOTER SECTION HERE */
.footer_bg {
  background-color: black;
}

.footer_bg_line {
  background-color: var(--color-light-white);
  height: 2px;
}

footer {
  margin-bottom: -1px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-07 {
  opacity: 0.7;
}

.hero_scroll_text {
  z-index: 1;
  bottom: 32px;
  right: 17px;
}

.h-100vh {
  height: 100vh;
}

@media (max-width: 767.98px) {
  .hero_scroll_text {
    bottom: 10px;
    right: 10px;
  }

  .svg_logo svg {
    width: 300px;
  }
}

/* DEFINE CARD CSS ======================*/

.Benefits_cards {
  background: var(--color-white);
  margin: 0 auto;
  border-radius: 4px;
  padding: 10px 27px 0px 12px;
  height: 270px;
  /* width: 150px !important; */
  cursor: pointer;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 576px) {
  .Benefits_cards {
    width: 173px !important;
  }
}

@media (min-width: 992px) {
  .Benefits_cards {
    width: 173px !important;
  }
}

@media (min-width: 1200px) {
  .Benefits_cards {
    width: 195px !important;
  }
}

.Benefits_cards_parent:hover .Benefits_cards {
  opacity: 0.5;
}

.Benefits_cards:hover {
  opacity: 1 !important;
}

.fw_500 {
  font-weight: 500;
}

.Benefits_cards h4 {
  color: var(--color-white);
  -webkit-text-stroke: 1px #0076c8;
  color: transparent;
}

.fs_18 {
  font-size: 18px;
}

.card_arrow {
  bottom: 10%;
  right: 10% !important;
}

.card_heading {
  line-height: none !important;
}

/*  DEFINE CYBORYX CSS ======================*/
.cyboryx-text-img {
  max-width: 344px;
  /* margin: 0 auto; */
}

.bg_img_after::after {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black
    url("https://vz-31b52218-178.b-cdn.net/f23b0d1f-1049-47d3-a3bf-30dcfcb3b384/thumbnail_6647bcf6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -1;
}

.bg_img_contact::after {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black
    url("https://vz-31b52218-178.b-cdn.net/2b3122cb-35d6-4b18-bf45-f536a56dc01f/thumbnail.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -1;
}

.bg_img_qatar::after {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black
    url("https://vz-31b52218-178.b-cdn.net/86f1d52a-c7cc-4912-933f-6fbef56f4aff/thumbnail_abb65eb1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -1;
}

button:disabled {
  pointer-events: none;
  opacity: 0.7;
}

.Toastify__progress-bar {
  background: #002baa !important;
}

@media (min-width: 768px) {
  .mt_nagtive_48 {
    margin-top: -48px;
  }

  .card_heading {
    line-height: 48px;
  }
}

@media (min-width: 1200px) {
  .mt_nagtive_96 {
    margin-top: -96px;
  }
}

@media (max-width: 767.98px) {
  .flex_warp {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media (max-width: 575.98px) {
  .Benefits_cards {
    max-width: 100% !important;
    height: 150px !important;
    padding: 20px;
  }

  .card_arrow {
    bottom: 15%;
    right: 5% !important;
  }

  .cards_pg {
    font-size: 16px;
  }

  .w_mobile_100 {
    width: 100%;
  }

  .hero_text {
    height: 300px;
  }
}

@media (max-width: 424.98px) {
  .cards_pg {
    font-size: 14px;
  }
}
